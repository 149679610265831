import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/components/spinner/spinner.service';
import { AlertService } from './alert.service';
import {StorageService} from './storage.service'
import {Location} from '@angular/common';
import { HttpService } from './http.service';
import { APIS } from 'src/constants';
import { SocketService } from './socket.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private spinner: SpinnerService,
    private alertService: AlertService,
    private storageService: StorageService,
    private _location: Location,
    private httpService: HttpService,
    private socketService: SocketService
  ) {}

  updateData() {
    this.socketService.disconnect();
    const refreshToken = this.storageService.getDataFromLocalstorage('refreshToken')||'';
    this.httpService.post(APIS.AUTH.REFRESH_TOKEN, { refreshToken: refreshToken }).subscribe({
      next: ( response:any )=>{
        this.socketService.connect();
        localStorage["refreshTokenRequested"] = false;
        this.storageService.saveDataIntoLocalStorage('token',response?.data?.token);
      },
      error: (error:any) => {
        localStorage.clear();
        this.socketService.stopNotification();
        this.router.navigate(['/auth/login']);
      }
    })
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.spinner.toggleSpinner();
    // Clone the request to add the new header.
    const token = this.storageService.getDataFromLocalstorage('token');
    if (token && !req.url.includes('refreshToken')) {
      req = req.clone({
        headers: req.headers.set('authorization', token),
      });
    }
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const message = event.body.msg;
            if(!message.includes('fetched')&&!message.includes('get')&&!message.includes('fetch')&&!message.includes('sent')&& !req.url.includes('refreshToken')){
              message && this.alertService.successToastr(message);
            }
            this.spinner.toggleSpinner();
          }
        },
        async (err: any) => {
          const status = err.error.statusCode;
          const message = err.error.msg;
          this.spinner.toggleSpinner();
          if (status === 401 && !req.url.includes('refreshToken')) {
            if(localStorage.getItem('refreshToken')&& !localStorage.getItem('refreshTokenRequested')) {
              localStorage["refreshTokenRequested"] = true;
              this.updateData();
            }
            else{
              localStorage.clear();
              this.socketService.stopNotification();
              this.router.navigate(['/auth/login']);
            }
          }
          else if( status===403 || message.includes("FORBIDDEN") ) // this condition is added to redirect back to the same page when user does'nt have permission to view particular route
          {
            this._location.back();
          }
          else if(message.includes("Your IP is not whitelisted. Please contact you administrator.") && token) {
            this.router.navigate(['/badrequest']); // condtion is to redirect user to the bad request page
          }
          else {
            this.alertService.errorToastr(message);
          }

        }
      )
    );
  }
}
